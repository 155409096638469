<template>
  <div class="company">
    <Head :headdata="headdata"></Head>
    <div class="companyMain common-main">
      <h2 class="title_zh">
        公司
        <div class="fenxBox">
          <img class="fenx" src="../assets/images/icon_fenx.png" alt />
          <p>分享</p>
          <share  :img="headdata.imgUrl" :desc="headdata.headTitZh"/>
        </div>
      </h2>
      <h2 class="title_en">Company</h2>
      <div class="htmlContent" v-html="htmlContent">
      
      </div>
    </div>
  </div>
</template>

<script>
import Head from "@/components/header.vue";

import share from "@/components/share.vue";
export default {
  data() {
    return {
      headdata: this.$t("m.companyHeaddata"),
      htmlContent:'',
      isShow: false,
    };
  },
  components: {
    Head,
    share
  },
  created() {
    this.getData();
  },
  updated() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  },
  methods: {
    getData() {
      this.axios
        .get(this.GLOBAL.serverSrc + "/wc/about") // 真实环境中，后端会根据参数group返回新的图片数组，这里我用一个惊呆json文件模拟
        .then((res) => {
          console.log(res);
            if(res.data.code==1){
             this.htmlContent=res.data.data.description;
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
  .htmlContent p img, .projectInfoContent p img{
      width: 60%;
      height: 100%;
      margin-left: 20%;
    }
    
    .company {
      height: 100%;
      width: 100%;
      .companyMain {
        width: 100%;
        padding: 78px 200px 0 200px;
        .title_zh {
          color: #000;
          font-size: 34px;
          .fenxBox {
            float: right;
            cursor: pointer;
            .fenx {
              width: 20px;
              height: 20px;
            }
            p {
              font-size: 12px;
              color: #808080;
            }
          }
        }
        .title_en {
          margin-bottom: 60px;
          font-size: 24px;
        }
        .title_text {
          font-size: 18px;
          max-width: 800px;
          margin-bottom: 60px;
          color: #000;
        }
        .imgBox {
          width: 100%;
          text-align: center;
          margin-bottom: 55px;
          img {
            margin: 50px 0;
            width: 64%;
            height: 100%;
          }
        }
        .companyText {
          li {
            float: left;
            text-align: center;
            width: 25%;
            b {
              color: #0066bb;
              font-size: 61px;
              span {
                width: 2em;
                font-size: 16px;
                line-height: 18px;
                display: inline-block;
                color: #000;
                font-weight: 600;
                bottom: 30px;
                position: relative;
              }
            }
            p {
              color: #000;
              font-weight: 600;
              font-size: 12px;
              font-family: "FZLTZCHK";
            }
          }
        }
        .companyContent{
          ul{
            li{
              margin-bottom: 55px;
              .title_zh{
                color: #000;
                font-size: 24px;
                font-weight: 800;
                margin-bottom: 6px;
              }
              .title_en{
                color: #0066bb;
                font-size: 12px;
                margin-bottom: 4px;
              }
              .contentText{
                color: #000;
                font-size: 16px;
                margin-top: 6px;
              }
            }
          }
        }
      }
    }
@media (max-width: 768px) {
  
}

</style>